import {API_REQUEST, GET_TOKEN_DATA, GET_TIER_TOKEN_DATA} from "../../constants/actionsTypes";

export const fetchTokenData = (tokenStr) => ({ 
    type: API_REQUEST,
    payload: Object.assign({
        url: `token/validation/`,
        data: {
            "token": tokenStr,
        },
        next: GET_TOKEN_DATA,
        method: 'PUT'
    })
}) 

export const fetchTierTokenData = (tokenStr) => ({ 
    type: API_REQUEST,
    payload: Object.assign({
        url: `token/validation/`,
        data: {
            "token": tokenStr,
        },
        next: GET_TIER_TOKEN_DATA,
        method: 'PUT'
    })
}) 
