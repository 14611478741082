import React from "react";
import "./LoadingSpinner.css"
import Logo from "../Logo";

export default function LoadingSpinner(props) {

    return (
        <div className={`spinner-container ${props.loading && "active"}`}>
            {props.heart &&
                (
                    <div className="dimmer">
                        <div className="lds-heart">
                            <div></div>
                        </div>
                    </div>
                )}
            {props.logo && (
                <div className="dimmer">
                    <div className="spinner"><Logo/></div>
                </div>
                
            )}

            {props.children}
        </div>



    )

}