import React, { useState, useEffect } from 'react';
import ScheduleTable from '../components/ScheduleTable'; // Make sure to import your ScheduleTable component
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { useParams, useSearchParams } from 'react-router-dom';
import fetchScheduleDatesFromAPI from '../utils/fetchScheduleDatesFromAPI';
import { fetchTierTokenData } from '../redux/actions/token/getToken';
import { useNavigate } from "react-router-dom";
import Countdown from 'react-countdown';

export default function MyTierAvailability(props) {

    const navigate = useNavigate();
    const [requestTokenSent, setReqTokenSent] = useState(false);
    let { token } = useParams();
    const [exp, setExp] = useState(new Date());
    const dispatch = useDispatch();
    const [playerData, setPlayerData] = useState(false)

    const tokenData  = useSelector((state) => state.tokensReducers.tierTokenData); // Store data for tokenData

    // go away breakpoint
    useEffect(() => {
        if(!tokenData.loading && !tokenData?.token && !tokenData.error && !requestTokenSent){
            setReqTokenSent(true)
            dispatch(fetchTierTokenData(token));
        } else if(tokenData.payload && !playerData) {
            setPlayerData(tokenData.payload);
            console.log(tokenData.payload); 
        }
    }, [tokenData])

    const [timeZone, setTimeZone] = useState('UTC');
    const [is24HourFormat, setIs24HourFormat] = useState(true);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);

    const adjustForTimeZoneAndGenerateTable = (selectedTimeZone, newDate, newDateEnd) => {
        // Convert UTC start and end dates to the selected time zone
        const offset = newDate.setZone(selectedTimeZone).offset;
        
        setStartDateTime(newDate.plus({hours: offset / 60}));
        setEndDateTime(newDateEnd.plus({hours: offset / 60}));
    };

    useEffect(() => {
        const { newDate, newDateEnd } = fetchScheduleDatesFromAPI();
        
        if (newDate){
            adjustForTimeZoneAndGenerateTable(timeZone, newDate, newDateEnd);
        }

    }, [timeZone]);


    return (
        <div className="App">
            <LoadingSpinner logo={true} loading={tokenData.loading}>
                <div className="App-header">
                    <h1>{props.isMatchup ? "My Match" : "My Tier"}</h1>
                    <select disabled value={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                        <option value="UTC">UTC</option>
                        <option value="America/New_York">America/New_York</option>
                        <option value="America/New_York">America/Honolulu</option>
                        {/* Add other time zones as needed */}
                    </select>
                    
                    <button disabled onClick={() => setIs24HourFormat(!is24HourFormat)}>
                        Toggle Time Format
                    </button>

                    {startDateTime && endDateTime && (
                        <ScheduleTable
                            startDateTime={startDateTime}
                            endDateTime={endDateTime}
                            mode={"read"}
                            is24HourFormat={is24HourFormat}
                            singlePlayer={false}
                            singleCellData={[]}
                            tierPlayers={true}
                            isMatchup={props.isMatchup}
                            multiCellData={playerData}
                        />
                    )}
                </div>
            </LoadingSpinner>
        </div>
    )
}