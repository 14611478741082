import { combineReducers } from "redux";
import getTokenReducer from "./getTokenReducer";
import availabilityReducer from "./availibityReducer";
import getTierTokenReducer from "./getTierReducer";


const tokensReducers = combineReducers({
    tokenData: getTokenReducer,
    tierTokenData: getTierTokenReducer,
    submitAvailability: availabilityReducer

});

export default tokensReducers;