import React, { useState, useEffect } from 'react';
import ScheduleTable from '../components/ScheduleTable'; // Make sure to import your ScheduleTable component
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import Logo from '../components/Logo';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchTokenData } from '../redux/actions/token/getToken';
import { submitAvailabilityData } from '../redux/actions/token/submitAvailabilty';
import fetchScheduleDatesFromAPI from '../utils/fetchScheduleDatesFromAPI';
import Countdown from 'react-countdown';
import { useNavigate } from "react-router-dom";

export default function MyAvailability(props) {

    const navigate = useNavigate();
    const [requestTokenSent, setReqTokenSent] = useState(false);
    const [updateToken, setUpdateToken] = useState(false);
    const [mybitstream, setMyBitStream] = useState([]);
    const [myUsername, setMyUsername] = useState("");
    const [exp, setExp] = useState(false);
    let { token } = useParams();
    const dispatch = useDispatch();
    const tokenData  = useSelector((state) => state.tokensReducers.tokenData); // Store data for tokenData
    const submitData  = useSelector((state) => state.tokensReducers.submitAvailability); // Store data for tokenData
    
    // go away breakpoint
    useEffect(() => {
        if(!tokenData.loading && !tokenData?.token && !tokenData.error && !requestTokenSent){
            setReqTokenSent(true)
            dispatch(fetchTokenData(token));
        } else if(tokenData.callback_token) {
            setUpdateToken(tokenData.callback_token);
            if(tokenData.payload.length) {
                setMyBitStream(tokenData.payload[0][0].bitstream);
                setMyUsername(tokenData.payload[0][0].player_name);
            }
            if(tokenData.expiresAt){
                setExp(tokenData.expiresAt);
            }            
        } else if (["Token is expired", "Token has already been consumed", "Could not find the specified token"].includes(tokenData.detail)) {
            navigate("/error")
        } 
    }, [tokenData])

    const [timeZone, setTimeZone] = useState('UTC');
    const [tableTz, setTableTz] = useState("UTC")
    const [is24HourFormat, setIs24HourFormat] = useState(true);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [generateMode, setGenerateMode] = useState("input");



    const adjustForTimeZoneAndGenerateTable = (selectedTimeZone, newDate, newDateEnd) => {
        // Convert UTC start and end dates to the selected time zone

        // const localStartDateTime = new Date(newDate.toLocaleString('en-US', { timeZone: selectedTimeZone }));
        // // const debug = formatDate(dataDateStart);
        // // const debug2 = formatDate(dataDateEnd);
        // // const localStartDateTime = new Date(formatDate(dataDateStart));
        // const localEndDateTime = new Date(newDateEnd.toLocaleString('en-US', { timeZone: selectedTimeZone }));
        // const localEndDateTime = new Date(formatDate(dataDateEnd));
        const offset = newDate.setZone(selectedTimeZone).offset;
        
        setStartDateTime(newDate.plus({hours: offset / 60}));
        setEndDateTime(newDateEnd.plus({hours: offset / 60}));
    };

    useEffect(() => {
        const { newDate, newDateEnd } = fetchScheduleDatesFromAPI();
        
        if (newDate){
            adjustForTimeZoneAndGenerateTable(tableTz, newDate, newDateEnd);
        }

    }, [tableTz]);

    const submit = (bitstream) => {
        dispatch(submitAvailabilityData(updateToken, bitstream))
        setGenerateMode("read");
    }

    return (
        <div className="App">
            <LoadingSpinner logo={true} loading={tokenData.loading || submitData.loading}>
                <div className="App-header">
                    <div className="top-center">
                        <h1>My Availability</h1>

                        <h3>Username: {myUsername}</h3>
                        <h3><Countdown
                            date={exp}
                            intervalDelay={0}
                            precision={3}
                            renderer={props => <div>Token expires in {props.minutes} minutes and {props.seconds} seconds</div>}
                        /></h3>
                        <details>
                            <summary>Debug</summary>
                            {updateToken}
                        </details>

                        <select value={tableTz} onChange={(e) => setTableTz(e.target.value)}>
                            <option value="UTC">UTC</option>
                            <option value="America/New_York">America/New_York</option>
                            <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                            <option value="Europe/Paris">Europe/Paris</option>
                            <option value="Asia/Jerusalem">Asia/Jerusalem</option>
                            {/* Add other time zones as needed */}
                        </select>
                        {/* <button onClick={() => setIs24HourFormat(!is24HourFormat)}>
                            Toggle Time Format
                        </button> */}
                    </div>

                    {startDateTime && endDateTime && (
                        <ScheduleTable
                            startDateTime={startDateTime}
                            endDateTime={endDateTime}
                            mode={generateMode}
                            is24HourFormat={is24HourFormat}
                            singlePlayer={true}
                            singleCellData={mybitstream}
                            multiCellData={[]}
                            submitCallBack={submit}
                            tz={tableTz}
                        />
                    )}
                </div>
            </LoadingSpinner>
        </div>
    )
}