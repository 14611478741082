const { DateTime } = require("luxon");

export default function fetchScheduleDatesFromAPI() {


    const now = DateTime.utc();
    const start = DateTime.utc(2024, 10, 21, 5, 0, 0, 0);
    
    // Example: The API provides the following UTC start and end dates for the week
    // const startDateEST = new Date("2024-10-21:04:00:00.000");
    // const startDateEST = (new Date(Date.UTC(2024, 9, 21, 4,0,0,0))).toISOString();
    // const now = new Date();
    // const utcNowTime = now.toISOString();

    // const dateStart = new Date(formatDate({date: startDateEST, offset: 0})) 
    // const dateNow = new Date(formatDate({date: utcNowTime, offset: 0})) 
    // const offset = startDateEST.getTimezoneOffset();
    // const startDateEST = new Date(startDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    // const endDateEST = new Date(2024, 11, 15, 23, 59, 59);

    // current day
    const diffTime = Math.abs(now - start);
    const numberOfDaysInto = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 

    // Find cloest Sunday by rounding down
    let nearestSun = numberOfDaysInto - (numberOfDaysInto % 7);
    // const newDate = new DateTime(start);
    // const newDateEnd = new DateTime(start);

    const newDate = start.plus({ days: nearestSun });
    const newDateEnd = start.plus({ days: nearestSun + 7});

    // const weekDelta = newDateEnd - newDate;
    // const properWeek = 608400000;
    // if (weekDelta != 608400000) {
    //     alert("Yikes");
    //     // Gotta deal with Daylight savings here lol
    //     // Set both dates to nearest midnight
    //     // do something
    //     // newDate.setHours(0);
    //     // newDateEnd.setHours(0);
    //     // do something else
    // }
    return { newDate, newDateEnd };
}

// Offset has opposite sign: 360 == -0600
// var data = {date: "2017-10-01T12:00:00.000Z",
//     offset: 360};
    
/* Return a timestamp adjusted for offset
** @param {object} data - object with the following properties 
**                          date: ISO 8601 format date and time string with Z offset
**                          offset: offset in minutes +ve west, -ve east
** @returns {string} ISO 8601 timestamp in zone
*/
function formatDate(data) {

    // Pad single digits with leading zero
    function pad(n){return (n<10? '0' : '') + n}

    // Format offset: 360 => -0600
    function formatOffset(offset) {
        var sign = offset < 0? '+' : '-'; // Note sign flip
        offset = Math.abs(offset);
        return sign + pad(offset/60|0) + pad(offset%60);
    }

    // Parse ISO 8601 date and time string, assume UTC and valid, ms may be missing
    function parseISO(s) {
        var b = s.split(/\D/);
        return new Date(Date.UTC(b[0],--b[1],b[2],b[3],b[4],b[5],b[6]|0));
    }

    var d = parseISO(data.date);
    var offset = data.offset;
    d.setUTCMinutes(d.getUTCMinutes() - offset);
    return d.toISOString().replace(/z$/i, formatOffset(offset));
}