const asyncActionType = (type) => ({
    PENDING: `${type}_PENDING`,
    SUCCESS: `${type}_SUCCESS`,
    ERROR: `${type}_ERROR`,
    RESET: `${type}_RESET`
  });

  /* API */
export const API_REQUEST = 'API_REQUEST';
export const API_REQUEST_POST = 'API_REQUEST_POST';
// example usage
export const GET_COLOUR_PALETTE = asyncActionType('GET_COLOUR_PALETTE');
export const GET_TOKEN_DATA = asyncActionType("GET_TOKEN_DATA");
export const GET_TIER_TOKEN_DATA = asyncActionType("GET_TIER_TOKEN_DATA");
export const PUT_TOKEN_DATA = asyncActionType("PUT_TOKEN_DATA");
