import {API_REQUEST, PUT_TOKEN_DATA} from "../../constants/actionsTypes";

export const submitAvailabilityData = (tokenStr, bitstring) => ({ 
    type: API_REQUEST,
    payload: Object.assign({
        url: `token/validation/`,
        data: {
            "token": tokenStr,
            "payload": [bitstring]
        },
        next: PUT_TOKEN_DATA,
        method: 'PUT'
    })
}) 
