// ROUTE TEMPLATE
// for future when moving to typescript
// {
//     component: JSX
//     path: string
//     children: [] or null
// }

import { Route } from "react-router-dom";
import MyAvailability from "../pages/MyAvailability";
import MyTierAvailability from "../pages/MyTierAvailability";

export const mainRoutes = [
    {
        component: <MyAvailability/>,
        path: "my-availability/:token"
    },
    {
        component: <MyTierAvailability isMatchup={false}/>,
        path: "my-tier/:token"
    },
    {
        component: <MyTierAvailability isMatchup={true}/>,
        path: "my-matchup/:token"
    },
    {
        component: (<>
            <h1>Token Has Expired</h1>
        </>),
        path: "/error"
    },
]

export const createRoutes = (mainRoutes) => {
    let routes = [];
    mainRoutes.forEach(route => {
        routes.push(<Route path={route.path} element={route.component}/>);
    });
    return routes
}