import React, { useState, useEffect } from 'react';
import ScheduleTable from './components/ScheduleTable'; // Make sure to import your ScheduleTable component
import {HashRouter, Routes} from 'react-router-dom';
import { createRoutes, mainRoutes } from './routes/routes';

function App() {
    console.log(process.env.REACT_APP_ROCKET_API);
    const routes = createRoutes(mainRoutes)
    return (
        <HashRouter basename="/">
            <Routes>
                {routes}
            </Routes>
        </HashRouter>
    )

}

export default App;
