import { GET_TOKEN_DATA } from "../../constants/actionsTypes";


// {
//   "token": "string",
//   "expiresAt": "string",
//   "payload": [
//     "string"
//   ],
//   "completed_operations": [
//     0
//   ]
// }

// Define an initial state value for the app
const initialState = {
    loading: false,
    error: false,
    numColors: 5,
}
// EXAMPLE REDUCER
// Create a "reducer" function that determines what the new state
// should be when something happens in the app
function getTokenReducer(state = initialState, action) {
    // Reducers usually look at the type of action that happened
    // to decide how to update the state

    const { payload } = action;

    switch (action.type) {
        case GET_TOKEN_DATA.PENDING:
            return {
                ...state,
                loading: true,
                error: false,
            }
        case GET_TOKEN_DATA.SUCCESS:

            return {
                ...state,
                ...payload,
                loading: false,
                error: false
            }

        case GET_TOKEN_DATA.ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case GET_TOKEN_DATA.RESET:
            return {
                ...state,
                ...initialState
            }
        default:
            // If the reducer doesn't care about this action type,
            // return the existing state unchanged
            return state
    }
}



export default getTokenReducer;