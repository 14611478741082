import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

let cell_deviation = 2;

const ScheduleTable = ({ startDateTime, endDateTime, mode = "input", singlePlayer, tierPlayers, singleCellData, multiCellData, submitCallBack, isMatchup, tz}) => {
    const [is24HourFormat, setIs24HourFormat] = useState(true);
    const [availability, setAvailability] = useState({});
    const [timezone, setTimezone] = useState('UTC');
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartCell, setDragStartCell] = useState(null);
    const [dragEndCell, setDragEndCell] = useState(null);
    const [shouldSetAvailable, setShouldSetAvailable] = useState(true);
    const [playersInCell, setPlayersInCell] = useState(true);
    const [playerCellData, setPlayerCellData] = useState([]);

    const formatHour = (hour, is24HourFormat) => {
        if (is24HourFormat) {
            return hour.toString().padStart(2, '0') + ':00';
        } else {
            const period = hour < 12 ? 'AM' : 'PM';
            const hour12 = hour % 12 === 0 ? 12 : hour % 12;
            return `${hour12}:00 ${period}`;
        }
    };
    

    const adjustTimeZone = (newTimezone) => {
        const updatedAvailability = {};
        const oldOffset = startDateTime.setZone(timezone).offset;
        const newOffset = startDateTime.setZone(newTimezone).offset;
        const offsetDifference = newOffset - oldOffset;

        for (let key in availability) {
            const [day, hour] = key.split('-').map(Number);

            // Calculate new hour with timezone offset difference
            const newHour = (hour + offsetDifference * cell_deviation + 96) % 96; // Adjust hour and ensure it's within 0-95
            const newKey = `${day}-${newHour}`;
            
            updatedAvailability[newKey] = availability[key];
        }
        
        console.log(availability);
        if(singleCellData && singlePlayer) {
            setTimezone(newTimezone);
            setAvailability(updatedAvailability);
            populateTableFromBitstream(singleCellData);
        }
    };

    useEffect(() => {
        adjustTimeZone(timezone);
    }, [startDateTime, endDateTime]);

    const toggleAvailability = (event, day, hour) => {
        if (event.target.classList.contains('disabled')) return;
    
        if (event.type === 'mousedown') {
            setIsDragging(true);
            setDragStartCell({ day, hour });
            clearPreviousSelection();
    
            // Determine the intended action based on the initial cell's state
            setShouldSetAvailable(!availability[`${day}-${hour}`]);
        } else if (event.type === 'mouseup') {
            setIsDragging(false);
            // Finalize the selection
            applyBoxSelection(dragStartCell, { day, hour }, false);
            // Clear drag-related state
            setDragStartCell(null);
            setDragEndCell(null);
        }
    };

    // const somedata = [{"name":"Sossa","bitstream":"0001010010101010"}]

    const populateFromTierList = (listofBitstreams, isMatchup) => {
        const table = document.getElementById('availabilityTable');
        let normalizedBitstreamData = [];

        // seperate our player avails from our player team names if 
        const availData = isMatchup ? listofBitstreams[0] : listofBitstreams;
        const teamNameData = isMatchup ? listofBitstreams[1] : null;

        // add data and find max number 
        //TODO Maybe don't hardcode this
        for (const x of Array(336).keys()){
            let cell = {
                "players": [],
                "value": 0,
            }
            availData.forEach((bitstreamdata, index) => {
                const bitstreamdataFix = isMatchup ? bitstreamdata : bitstreamdata[0]
                if(bitstreamdataFix.bitstream.length > 1){
                    const playerSel = Number(bitstreamdataFix.bitstream[x])
                    cell.value += playerSel;
                    if(playerSel === 1) {
                        // if we are displaying a matchup add the team names to the player in colon seperated
                        cell.players.push(isMatchup ? {
                            "player": bitstreamdataFix.player_name,
                            "team": teamNameData[index]
                        }: `${bitstreamdataFix.player_name}`);
                    }
                }
            });
            normalizedBitstreamData.push(cell);
            
        }
        setPlayerCellData(normalizedBitstreamData);
        let bitIndex = 0;
        const rows = Array.from(table.rows).slice(1);
        const columns = rows[0].cells.length;
        for (let col = 1; col < columns; col++) {
            for (let row = 0; row < rows.length; row++) {
                const cell = rows[row].cells[col];
                const cellData = normalizedBitstreamData[bitIndex];
                
                if(cellData) {
                    if (!cell.classList.contains('disabled')) {
                        if(isMatchup) {
                            cell.classList.add("matchup");
                            let counts = {};
                            cellData.players.forEach((player) => {
                                if(!(player.team in counts)) {
                                    counts[player.team] = 1;
                                } else {
                                    counts[player.team] += 1;
                                }
                                
                            });
    
                            let twoTeams = Object.keys(counts).length > 1;
                            let correctPlayers = true;
                            for(const [key, value] of Object.entries(counts)){
                                correctPlayers &= (value >= 3)
                            }
                            twoTeams && correctPlayers ? cell.classList.add(`available-match`) : cell.classList.add(`notavailable-match`); 
                            
                            cell.setAttribute("data-players-index", bitIndex)
                        } else {
                            if(cell)
                            cell.setAttribute("data-players", `["${cellData.players.join(`","`)}"]`)
                            cell.classList.add(`available-${cellData.value}`); 
                        }
                        bitIndex++;
                    }
                    
                }
            }
        }
    }

    const populateTableFromBitstream = (bitstream) => {
        const table = document.getElementById('availabilityTable');

        if (!table) {
            console.error("Table with ID 'availabilityTable' not found.");
            return;
        }

        const updatedAvailability = {};
        let bitIndex = 0;

        const rows = Array.from(table.rows).slice(1);
        const columns = rows[0].cells.length;

        for (let col = 1; col < columns; col++) {
            for (let row = 0; row < rows.length; row++) {
                const cell = rows[row].cells[col]

                if (!cell.classList.contains('disabled')) {
                    const isAvailable = bitstream[bitIndex] === '1';
    
                    if (isAvailable) {
                        cell.classList.add('available'); // Add 'available' class if the bit is 1
                    } else {
                        cell.classList.remove('available'); // Remove 'available' class if the bit is 0
                    }
    
                    bitIndex++;
                }
            }
        }

        //setAvailability(updatedAvailability);
    };

    const handleDrag = (event, day, hour) => {
        // Read only meaning we
        if(mode === "read" && tierPlayers) {
            const players = JSON.parse(event.target.getAttribute("data-players"));
            const index = event.target.dataset.playersIndex;
            if(players && !isMatchup) {
                const playerComponents = [];
                players.forEach((name) => {
                    playerComponents.push((<div>{name}</div>))
                })
                setPlayersInCell(playerComponents);
            } else if(index && isMatchup) {
                const playerComponents = {}
                const components = [];
                const cellData = playerCellData[index];
                cellData.players.forEach((player) => {
                    if(!(player.team in playerComponents)){
                        playerComponents[player.team] = {
                            players: [player.player]
                        }
                    }else{
                        playerComponents[player.team].players.push(player.player)
                    }
                })
                components.push(<h3>CELL INDEX: {Number(index) + 1}</h3>)
                for(const [key, value] of Object.entries(playerComponents)){
                    components.push(<div>
                        <h3>{key}</h3>
                        {value.players.map((player) => <div>{player}</div>)}
                    </div>)
                }
                setPlayersInCell(components);
            }
        }
        else if (isDragging) {
            setDragEndCell({ day, hour });
            // Apply the preview during dragging
            applyBoxSelection(dragStartCell, { day, hour }, true);
        }
    };

    const clearPreviousSelection = () => {
        // Reset any preview classes before a new selection starts
        const updatedAvailability = { ...availability };
        for (let key in updatedAvailability) {
            if (updatedAvailability[key] === 'highlight-available' || updatedAvailability[key] === 'highlight-unavailable') {
                delete updatedAvailability[key];
            }
        }
        setAvailability(updatedAvailability);
    };

    const clearPreview = () => {
        // Convert all preview classes to final classes after mouse up
        const updatedAvailability = { ...availability };
        for (let key in updatedAvailability) {
            if (updatedAvailability[key] === 'highlight-available') {
                updatedAvailability[key] = true;
            } else if (updatedAvailability[key] === 'highlight-unavailable') {
                updatedAvailability[key] = false;
            }
        }
        setAvailability(updatedAvailability);
    };

    const applyBoxSelection = (startCell, endCell, isPreview) => {
        if (!startCell || !endCell) return;
    
        const startRow = startCell.day;
        const startCol = startCell.hour;
        const endRow = endCell.day;
        const endCol = endCell.hour;
    
        const minRow = Math.min(startRow, endRow);
        const maxRow = Math.max(startRow, endRow);
        const minCol = Math.min(startCol, endCol);
        const maxCol = Math.max(startCol, endCol);
    
        const updatedAvailability = { ...availability };
    
        // Remove previous previews if dragging back
        if (isPreview) {
            const previousKeys = Object.keys(availability).filter(key =>
                availability[key] === 'highlight-available' || availability[key] === 'highlight-unavailable'
            );
            
            previousKeys.forEach(key => {
                delete updatedAvailability[key];
            });
        }
    
        // Apply new selection range
        for (let row = minRow; row <= maxRow; row++) {
            for (let col = minCol; col <= maxCol; col++) {
                const key = `${row}-${col}`;
                if (isPreview) {
                    // Apply the preview highlight only during dragging
                    updatedAvailability[key] = shouldSetAvailable ? 'highlight-available' : 'highlight-unavailable';
                } else {
                    // Apply the final selection state on mouse up
                    updatedAvailability[key] = shouldSetAvailable;
                }
            }
        }
    
        setAvailability(updatedAvailability);
    };

    const getMarkedSlots = () => {
        const table = document.getElementById('availabilityTable');
        let bitstream = '';
    
        // Get all rows except the header row
        const rows = Array.from(table.rows).slice(1); // Remove header row
        const columns = rows[0].cells.length; // Number of columns (including the time labels column)
    
        // Iterate over columns (starting from the first time-slot column)
        for (let col = 1; col < columns; col++) { // Start from 1 to skip the time labels
            // Iterate down each row in the current column
            for (let row = 0; row < rows.length; row++) {
                const cell = rows[row].cells[col]; // Access the cell in the current column
    
                // Ignore disabled cells
                if (!cell.classList.contains('disabled')) {
                    bitstream += cell.classList.contains('available') ? '1' : '0';
                }
            }
        }
    
        console.log(bitstream); // Dev
        return bitstream;
    };

    const handleSubmit = () => {
        const bitstream = getMarkedSlots();
        submitCallBack(bitstream);
    };

    const generateTableRows = () => {
        const hours = 24 * cell_deviation; // 4 slots per hour for 24 hours
        let currentDay = startDateTime.startOf('day')
        let endDay = endDateTime.startOf('day')

        const days = Math.ceil((endDay - currentDay) / (1000 * 60 * 60 * 24)) + 1;
        let currentDate = startDateTime;
        const rows = [];

        const headerRow = (
            <tr key="header-row">
                <th></th>
                {Array.from({ length: days }).map((_, day) => {
                    const localDate = currentDate;
                    currentDate = currentDate.plus({day: 1});
                    return (
                        <th key={`header-${day}`} className="date-header">
                            {`${localDate.toLocaleString({ month: 'short', day: 'numeric' })}\n${localDate.toLocaleString({ weekday: 'short' })}`}
                        </th>
                    );
                })}
            </tr>
        );

        rows.push(headerRow);

        for (let hour = 0; hour < hours; hour++) {
            const cells = [];

            if (hour % cell_deviation === 0) {
                const hourValue = Math.floor(hour / cell_deviation);
                cells.push(
                    <th key={`hour-${hour}`} className="hour-marker" data-hour-value={hourValue}>
                        {formatHour(hourValue, is24HourFormat)}
                    </th>
                );
            } else {
                cells.push(<th key={`empty-hour-${hour}`} className="hour-marker"></th>);
            }

            currentDate = startDateTime;

            for (let day = 0; day < days; day++) {
                const slotTime = currentDate.set({hours: hour / cell_deviation, minutes: (hour % cell_deviation) * 15});

                // slotTime.setHours(hour / cell_deviation, (hour % cell_deviation) * 15, 0, 0);

                const isDisabled = slotTime < startDateTime || slotTime >= endDateTime;
                const key = `${day}-${hour}`;
                const isAvailable = availability[key];
                const cellClasses = `time-slot${isDisabled ? ' disabled' : ''} ${isAvailable === true ? 'available' : ''} ${isAvailable === 'highlight-available' ? 'highlight-available' : ''} ${isAvailable === 'highlight-unavailable' ? 'highlight-unavailable' : ''}`;

                cells.push(
                    <td
                        key={`day-${day}-hour-${hour}`}
                        className={cellClasses}
                        data-date={slotTime.toISO().split('T')[0]}
                        data-time-slot={hour}
                        onMouseDown={(event) => mode === "input" && toggleAvailability(event, day, hour)}
                        onMouseOver={(event) => handleDrag(event, day, hour)}
                        onMouseUp={(event) => mode === "input" && toggleAvailability(event, day, hour)}
                    ></td>
                );

                currentDate = currentDate.plus({day: 1});
            }

            rows.push(<tr key={`row-${hour}`}>{cells}</tr>);
        }

        return rows;
    };

    const rows = generateTableRows();

    useEffect(() => {
        if(singlePlayer && singleCellData){
            populateTableFromBitstream(singleCellData);
        }
    }, [singlePlayer, singleCellData])

    useEffect(() => {
        if(tierPlayers && multiCellData){
            populateFromTierList(multiCellData, isMatchup);
        }
    }, [tierPlayers, multiCellData])


    return (
        <div className="flex-main">
        {tierPlayers && <h3>Current Cell's Players 
            <div className="player-in-cell">{playersInCell}</div>
        </h3>}
        <div id="availabilityTableContainer" onMouseLeave={() => isDragging && setIsDragging(false)}>
            <table id="availabilityTable">
                <tbody>{rows}</tbody>
            </table>
            {mode === "input" && <button onClick={handleSubmit}>Submit Availability</button>}
        </div>
        </div>
    );
};

export default ScheduleTable;